#container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100svh;
}

:root {
  --background-color: #fdfdfd;
  --past-color: #adadad;
  --text-color: #313131;
}

body {
  background-color: var(--background-color);
  font-family: Arial, Helvetica, sans-serif;
  height: 100svh;
}

.box-svg {
  height: 50svh;
  width: 50svh;
}

path, circle, rect {
  stroke-width: 5px;
}

text {
  font-size: 60px;
  text-anchor: middle;
}

p {
  font-size: 24px;
  text-align: center;
}

p.error {
  font-size: 16px;
}

span.join {
  color: var(--past-color);
}

.row {
  display: flex;
  width: 90%;
  text-align: center;
  max-height: 50svh;
  justify-content: center;
}

svg {
  margin: auto;
}

path, circle, rect {
  stroke-width: 5px;
  stroke: black;
}

.active {
  stroke: var(--background-color);
}

path.active {
  stroke-dasharray: 4% 4%;
}

path.past {
  stroke: var(--past-color);
}

circle {
  fill: white;
}

circle.past {
  fill: var(--past-color);
}

circle.lead {
  fill: black;
  stroke: var(--background-color);
}

text {
  font-size: 60px;
  text-anchor: middle;
  font-family: Arial, Helvetica, sans-serif;
  fill: var(--text-color);;
}

text.past,text.active {
  fill: black;
}

text.active {
  font-weight: bold;
}

input {
  pointer-events: none;
  background-color: var(--background-color);
  border: none;
  font-size: 60px;
  outline: none;
  width: 80%;
  text-align: center;
  border-bottom: 2px solid black;
  color: var(--text-color);
}

button {
  font-size: 20px;
  color: black;
  -webkit-text-fill-color: black;
  background-color: var(--background-color);
  border: 2px solid black;
  border-radius: 1em;
  width: 7rem;
  height: 2.2rem;
  margin: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  flex-direction: column; /* Stack items vertically */
}

.banner {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically within the banner */
  width: 100%; /* Optional: If you want the banner to stretch across the full width of the screen */
  text-align: center; /* Ensure the text within the banner is centered */
  font-size: 3rem;
  color: #f5deb3;
  margin: 0; /* Remove default margin to prevent overlap issues */
}

.new-combination {
  font-size: 2rem;
  margin: 10px 0; /* Add spacing between items */
  text-align: center; /* Center the text within the new-combination */
  color: #f5deb3;
}


.modal {
  display: flex;
  position: absolute;
  top: 0;
  background-color: var(--past-color);
  height: 100svh;
  margin: 10px;
  border-radius: 1em;
  text-align: center;
}

.modal p {
  font-size: 16px;
  text-align: left;
  padding: 0rem 1rem;
  margin: .5rem 1rem;
}

.modal button {
  margin-left: 1rem;
}

.modal img {
  margin-top: 20px;
  margin-right: 7.5px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 50px;
}

.povrzilogo {
  display: flex;
  width: 20%; 
  height: auto;
}

.streak{
  font-size:20px;
  margin-left: 20px;
}

/* Apply this style only on mobile devices */
@media (max-width: 1100px) {

  .title-container {
    margin-bottom: 30px;
  }
  
  .povrzilogo {
    width: 200px;
  }

  .streak{
    font-size:10px;
    margin-left: 20px;
  }

  .banner {
    font-size: 2rem;
  }
  
  .box-svg {

  }

  .new-combination{
    font-size: 1rem;
  }

  input {
    font-size: 40px;
  }
  
  
}
